import { useState } from "react";
import homeStyles from "../projects/Home.module.css";
import featuredStyles from "../projects/Featured.module.css";
import caitie2 from "../../vector_art/caitie2.jpg";
import chopra from "../../vector_art/chopra.jpg";

//import Navpanel from "../pageElements/navpanel.js";

const Team = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  function navToggle() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div className={homeStyles.container}>
      <div className={homeStyles.innerContainer}>
        {/*
        <Navpanel
          navToggle={navToggle}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        */}
        <div className={featuredStyles.featuredGrid}>
          {/****    NOVODRAFT START    *****/}
          <div className={homeStyles.cardImageRight}>
            <div className={featuredStyles.gitBoxRight}>
              <>
                <img
                  src="https://imagedelivery.net/tOBsYzfwEvd5k1eoqpi24A/4fb6d59a-8d2b-4056-41d0-e8510e8e2f00/public"
                  className={featuredStyles.headShotBox}
                />
              </>
            </div>
          </div>
          <div className={homeStyles.cardTextLeft}>
            <h3>Ravinder Chopra </h3>
            <p>
              Ravinder ("Ravi") is a data scientist developing feature/model
              pipelines optimized for finding trends and commonalities in the
              ways people interact with technology. His work reveals process
              efficiencies, generates predictive recommendations, and gives rise
              to new sibling and progeny features.{" "}
            </p>
            <p className={featuredStyles.bioGraph}>
              Ravi understands data from the highest level down to the finest
              granularity. He's prodigal at extracting patterns from
              complexity... And also pickleball - he’s really good at pickleball
              (he made us add that).
            </p>
          </div>

          {/****    EXF END    *****/}
          {/****    SF START    *****/}
          <div className={homeStyles.cardTextRight}>
            <div className={featuredStyles.caitBox}>
              <h3>Caitlin Masterson</h3>
            </div>

            <p>
              Caitlin is a highly versatile, talented engineer specializing in
              AI api architecture and prompt engineering. She’s comfortable and
              adept working in any part of a technology stack, front to back.
            </p>
            <p className={featuredStyles.bioGraph}>
              She’s been leveraging AI to increase her efficiency in developing
              features and deploying them via intelligent automation. She uses
              the time and resources saved to fuel her professioanal devlopment,
              and the company's growth.
            </p>
          </div>
          <div className={homeStyles.cardImageLeft}>
            <a style={{ marginTop: "6px" }}> </a>
            <div className={featuredStyles.featBox}>
              <div className={featuredStyles.freqBawks}>
                <img
                  src="https://imagedelivery.net/tOBsYzfwEvd5k1eoqpi24A/353f85ea-3909-40fb-3ee6-487c38219200/public"
                  className={featuredStyles.headShotBoxRight}
                />
              </div>
            </div>
          </div>
          {/****    SF END    *****/}
        </div>

        <style jsx>{`
          main {
            padding: 5rem 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          footer {
            width: 100%;
            height: 100px;
            border-top: 1px solid #eaeaea;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          footer img {
            margin-left: 0.5rem;
          }
          footer a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: inherit;
          }
          code {
            background: #fafafa;
            border-radius: 5px;
            padding: 0.75rem;
            font-size: 1.1rem;
            font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
              DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
          }
        `}</style>

        <style jsx global>{`
          html,
          body {
            height: 100vh;
            padding: 0;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
            background-color: #fff;
          }
          * {
            box-sizing: border-box;
          }
        `}</style>
      </div>
    </div>
  );
};

export default Team;
